import * as React from 'react';
import { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  EditButton,
  Pagination,
  ReferenceArrayField,
  ReferenceInput,
  AutocompleteInput,
  BulkExportButton,
  DataProvider,
  Exporter,
  FunctionField,
} from 'react-admin';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import TranslatedField from 'src/components/TranslatedField';
import DebouncedTextInput from 'src/components/DebouncedTextInput';
import HiddenInput from 'src/components/HiddenInput';
import { reviewedWorkStatus } from '../book/field';

import jsonExport from 'jsonexport/dist';

export type InputBibliographicInfo = {
  isbn: string;
  publisherInfo?: { name: string };
  bookType: string;
  lsStartPublicationDate: string;
  lsEndPublicationDate: string;
  pdfDataUrl: string;
  allowPDFPublish: number;
  allowWebPublish: number;
  allowDownloadAndPrint: number;
  allowCopyContent: number;
  tosNotAllowedPage: string;
  isExistCoverImage: number;
  bookTitle: string;
  coverAuthor: string;
  colophonPageNumber: string;
  colophonAuthor: string;
  bookVersion: string;
  printing: string;
  startPublicationDate: string;
  fullTitle: string;
  printerInfo?: { name: string };
  purchaseType: string;
  docInfo: {
    id: number | null;
    es: boolean | null;
    packIds: string[];
    purchaseType: string | null;
  };
};

// Lame... just copied from the file below to remove .csv extension...
// https://github.com/marmelab/react-admin/blob/master/packages/ra-core/src/export/downloadCSV.ts
const download = (csv: string, filename: string): void => {
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
  if (
    window.navigator &&
    (window.navigator as unknown as { msSaveOrOpenBlob: (blob: Blob, filename: string) => void }).msSaveOrOpenBlob
  ) {
    // Manage IE11+ & Edge
    (window.navigator as unknown as { msSaveOrOpenBlob: (blob: Blob, filename: string) => void }).msSaveOrOpenBlob(
      blob,
      `${filename}.csv`,
    );
  } else {
    fakeLink.setAttribute('href', URL.createObjectURL(blob));
    fakeLink.setAttribute('download', `${filename}`);
    fakeLink.click();
  }
};

const getCurrentDatetimeString = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are 0 based, so we add 1
  const day = ('0' + date.getDate()).slice(-2);
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  const seconds = ('0' + date.getSeconds()).slice(-2);
  return year + month + day + hours + minutes + seconds;
};

const formatDatetimeString = (datetimeString: string) => {
  const date = new Date(datetimeString);
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are 0 based, so we add 1
  const day = ('0' + date.getDate()).slice(-2);
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  return `${year}/${month}/${day} ${hours}:${minutes}`;
};

type Mapping = {
  from: string;
  to: string;
  resolve: (d: InputBibliographicInfo) => string | number | undefined;
};

type CsvRow = { [key: string]: string | number | undefined };

const exportToFile = (
  data: InputBibliographicInfo[],
  mappings: Mapping[],
  filename: string,
  delimiter = ',',
  augment = (row: CsvRow): CsvRow => row,
) => {
  const fields = mappings.map((m) => m.from);
  jsonExport(
    data.map((d) => {
      const row: CsvRow = {};
      fields.forEach((f) => {
        const m = mappings.find((m) => f === m.from) as Mapping;
        row[m.to] = m?.resolve(d);
      });
      return augment(row);
    }),
    {
      rename: fields.map((f) => (mappings.find((m) => m.from === f) as Mapping).to),
      rowDelimiter: delimiter,
    },
    (err: unknown, csv: string) => download(csv, filename),
  );
};

const exporter4Scripts: Exporter = (
  data: InputBibliographicInfo[],
  _: unknown,
  __: DataProvider,
  resource?: string,
) => {
  const mappings: Mapping[] = [
    { from: 'isbn', to: 'ISBN', resolve: (d: InputBibliographicInfo) => d.isbn },
    { from: 'publisherInfo', to: '出版社（発行元）', resolve: (d: InputBibliographicInfo) => d.publisherInfo?.name },
    { from: 'bookType', to: '種別', resolve: (d: InputBibliographicInfo) => d.bookType },
    {
      from: 'lsStartPublicationDate',
      to: '掲載開始日',
      resolve: (d: InputBibliographicInfo) => formatDatetimeString(d.lsStartPublicationDate),
    },
    {
      from: 'lsEndPublicationDate',
      to: '掲載終了日',
      resolve: (d: InputBibliographicInfo) => formatDatetimeString(d.lsEndPublicationDate),
    },
    { from: 'pdfDataUrl', to: '書誌情報入力用PDFデータURL', resolve: (d: InputBibliographicInfo) => d.pdfDataUrl },
    { from: 'allowPDFPublish', to: 'PDF掲載', resolve: (d: InputBibliographicInfo) => (d.allowPDFPublish ? 1 : 0) },
    { from: 'allowWebPublish', to: 'Web掲載', resolve: (d: InputBibliographicInfo) => (d.allowWebPublish ? 1 : 0) },
    {
      from: 'allowDownloadAndPrint',
      to: '印刷・ダウンロード',
      resolve: (d: InputBibliographicInfo) => (d.allowDownloadAndPrint ? 1 : 0),
    },
    {
      from: 'allowCopyContent',
      to: '内容のコピー',
      resolve: (d: InputBibliographicInfo) => (d.allowCopyContent ? 1 : 0),
    },
    { from: 'tosNotAllowedPage', to: '掲載不可ページ', resolve: (d: InputBibliographicInfo) => d.tosNotAllowedPage },
    {
      from: 'isExistCoverImage',
      to: '表紙画像の有無',
      resolve: (d: InputBibliographicInfo) => (d.isExistCoverImage ? 1 : 0),
    },
    { from: 'bookTitle', to: 'タイトル', resolve: (d: InputBibliographicInfo) => d.bookTitle },
    { from: 'coverAuthor', to: '著者・編者等', resolve: (d: InputBibliographicInfo) => d.coverAuthor },
    {
      from: 'colophonPageNumber',
      to: '奥付のページ番号',
      resolve: (d: InputBibliographicInfo) => d.colophonPageNumber,
    },
    {
      from: 'colophonAuthor',
      to: '著者・編集者・編者・編著者・監修者',
      resolve: (d: InputBibliographicInfo) => d.colophonAuthor,
    },
    { from: 'bookVersion', to: '版', resolve: (d: InputBibliographicInfo) => d.bookVersion },
    { from: 'printing', to: '刷', resolve: (d: InputBibliographicInfo) => d.printing },
    { from: 'undefined', to: '当該版最新刷の発行年月日', resolve: (d: InputBibliographicInfo) => undefined }, // FIXME
    {
      from: 'startPublicationDate',
      to: '発行年月日',
      resolve: (d: InputBibliographicInfo) => d.startPublicationDate.replaceAll('-', '/'),
    },
    { from: 'fullTitle', to: '全タイトル（順番保持）', resolve: (d: InputBibliographicInfo) => d.fullTitle },
    { from: 'printerInfo', to: '印刷所', resolve: (d: InputBibliographicInfo) => d.printerInfo?.name },
  ];
  exportToFile(data, mappings, `exported-reviewed-books-for-scripts-${getCurrentDatetimeString()}.tsv`, '\t');
};

const exporter4Shopify = (data: InputBibliographicInfo[], _: unknown, __: DataProvider, resource?: string) => {
  const mappings: Mapping[] = [
    { from: 'isbn', to: 'Handle', resolve: (d: InputBibliographicInfo) => d.isbn },
    { from: 'bookTitle', to: 'Title', resolve: (d: InputBibliographicInfo) => d.bookTitle || d.fullTitle },
    // TODO: value for "Body" column might not be enough with this
    {
      from: 'coverAuthor',
      to: 'Body',
      resolve: (d: InputBibliographicInfo) => `<p>著者・編者: ${d.coverAuthor || d.colophonAuthor}</p>`,
    },
  ];
  exportToFile(
    data.filter((d) => d.purchaseType === 'purchasable'),
    mappings,
    `exported-reviewed-books-for-shopify-${getCurrentDatetimeString()}.csv`,
    ',',
    (row: CsvRow) => {
      // add columns based on exported "ls-store-stage" product entries
      row.Status = 'draft'; // should not be "active" right before import operation
      row.Published = 'TRUE';
      row['Variant SKU'] = `'${row.Handle}`; // not sure though
      row['Variant Inventory Policy'] = 'deny';
      row['Variant Requires Shipping'] = 'FALSE';
      row['Variant Taxable'] = 'TRUE';
      // row['Image Position'] = 1;
      row['Gift Card'] = 'FALSE';
      row['Variant Weight Unit'] = 'kg'; // is this required?
      row['Included / Japan'] = 'TRUE';
      row['Included / International'] = 'TRUE';
      // TODO: no data source for these columns at the moment
      // row['Variant Price'] = 1000;
      // row['Image Src'] = 'https://cdn.shopify.com/s/files/1/0832/6620/8019/files/9784785728205.png?v=1696555432;
      return row;
    },
  );
};

export const ReviewedBookIcon = MenuBookIcon;

const ListActions: React.FC = () => <TopToolbar />;
const filters = [
  <DebouncedTextInput source="isbn" alwaysOn />,
  <DebouncedTextInput source="fullTitle" alwaysOn />,
  <DebouncedTextInput source="coverAuthor" alwaysOn />,
  <ReferenceInput source="publisherId" reference="publishers" alwaysOn>
    <AutocompleteInput optionText="name" label="resources.publishers.name" sx={{ width: 300 }} />
  </ReferenceInput>,
  <HiddenInput source="workStatus" defaultValue={reviewedWorkStatus.workStatusType} />,
];

const BulkActionButtons = () => (
  <Fragment>
    <BulkExportButton label="掲載用TSVエクスポート" exporter={exporter4Scripts} />
    <BulkExportButton label="Shopify用CSVエクスポート" exporter={exporter4Shopify} />
  </Fragment>
);

export const ReviewedBookList: React.FC = () => {
  return (
    <List
      actions={<ListActions />}
      filters={filters}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100]} />}
    >
      <Datagrid bulkActionButtons={<BulkActionButtons />} rowClick="show">
        <TextField source="id" />
        <TextField source="isbn" sortable={false} />
        <TextField source="fullTitle" sortable={false} />
        <TextField source="publisherInfo.name" sortable={false} />
        <TextField source="coverAuthor" sortable={false} />
        <TranslatedField source="allowPDFPublish" prefix="enum.allowPDFPublish" sortable={false} />
        <TranslatedField source="allowWebPublish" prefix="enum.allowWebPublish" sortable={false} />
        <TranslatedField source="purchaseType" prefix="enum.purchaseType" sortable={false} />
        <FunctionField
          source="docInfo.id"
          sortable={false}
          render={(record?: InputBibliographicInfo) => {
            if (!record?.docInfo.id) {
              return '';
            }
            if (!record?.docInfo.es) {
              return `Doc ID: ${record.docInfo.id}、 ES なし`;
            }
            return `Doc ID: ${record.docInfo.id}、ES あり`;
          }}
        />
        <ReferenceArrayField source="docInfo.packIds" reference="packs" sortable={false} />
        <TranslatedField source="docInfo.purchaseType" prefix="enum.esPurchaseType" sortable={false} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export * from './update';
